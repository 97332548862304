import {React, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import StickyFooter from './components/StickyFooter';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
    }, [location]);
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <StickyFooter />
    </div>
  );
}

export default App;