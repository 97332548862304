import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './About.css';
import HeroImage from '../Images/SD_FreshlyCleaned_73_3.jpg';
import Footer from './Footer';

function About() {
  return (
    <Container>
      <br/>

      <Container className="hero_image">
        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image src={HeroImage} fluid style={{ maxWidth: "100%", height: "auto" }} alt="Aqua Bright Solar Cleaning Website Hero Image" />
        </Row>
      </Container>   

      <Container className='About_Page_Header'>
      <h1><b><u>About Us</u></b></h1>
      <br/>
      </Container>
      

      <Container className="About-body2">
      <h2><b><u>Professional and Friendly</u></b></h2>
      <p>Our team understands the importance of thorough, careful work. We use the latest tools and techniques to deliver results that shine. 
        Your satisfaction is our priority, and we're dedicated to exceeding your expectations with our services.
      </p>
      <br/>
      </Container>


      <Container className="About-body1">
      <h2><b><u>Family Focused</u></b></h2>
      <p>Aqua Bright Solar Cleaning is a family-owned and operated business committed to helping you make the most of your solar panel investment. 
        We're proud to provide top-notch solar panel cleaning, bird proofing, exterior window cleaning, gutter cleaning, pressure washing, and car cleaning services to our community.
      </p>
      <br/>
      </Container>
    
      <Container className="About-body3">
      <h2><b><u>Fair and Honest</u></b></h2>
      <p>When you choose Aqua Bright Solar Cleaning, you're not just getting a service - you're joining our family. 
        We look forward to helping you enhance your home's exterior and solar panel efficiency with our services.
      </p>
      <br/>
      </Container>        

      {/* <Container className='BlueSeparator'>
        <Row>
          <p></p>
          <p></p>
        </Row>
      </Container> */}
      
      {/* <Footer />             */}
      
      

    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    </Container>
  );
}

export default About;