import React from 'react';
import { Container, Row, Col, Image,Button } from 'react-bootstrap';
import './Services.css';
import serviceArea from '../Images/SD_FreshlyCleaned_73_4.jpg';
import Footer from './Footer';

function Services() {
  return (
    <Container className="services-page">

      <Container className='services_hero_image'>
        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image src={serviceArea} fluid style={{ maxWidth: "100%", height: "auto" }} alt="Aqua Bright Solar Cleaning Service Area Bird's Eye View" />
        </Row>
      </Container>

      <Container>      
      <h1 className='Services_Page_Header'><b><u>Aqua Bright Solar Cleaning Services</u></b></h1>
      <br/>
      </Container>

      <Container className="Services_Page_Body">
      <h2><b><u>Solar Panel Cleaning</u></b></h2>
      <p>
        Ensure optimal solar energy production with our expert solar panel cleaning services. Over time, dust, dirt, and debris can accumulate on your panels, reducing their efficiency. 
      </p>
      <p>
        At Aqua Bright Solar Cleaning, we've been trained to use safe, efficient, and professional methods to restore the pristine condition of your panels, maximizing energy output and extending the life of your investment. Schedule a cleaning today and boost your solar panel performance.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Solar Panel Bird Proofing</u></b></h2>
      <p>
          Protect your solar investment from pesky birds and potential damage. Birds can nest under solar panels, leading to equipment damage and decreased panel efficiency. Our bird-proofing solutions deter birds from settling, ensuring your panels remain in top condition. Rely on our effective and humane methods to safeguard your solar setup.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Exterior Window Cleaning</u></b></h2>
      <p>
        Enjoy crystal-clear views with our comprehensive window cleaning solutions. From residential homes to commercial buildings, our skilled team ensures streak-free, sparkling windows. Using safe, eco-friendly cleaning agents, we remove dirt, fingerprints, and smudges, offering you a clearer outlook on the world. Enhance your property's aesthetic with our expert window washing service.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Pressure Washing</u></b></h2>
      <p>
        Revitalize the look of your property with our professional pressure washing services. Dirt, grime, and mildew can take a toll on your home's exterior, driveways, and patios. Our high-pressure cleaning method safely and effectively removes these unsightly contaminants, enhancing curb appeal and protecting your property. Experience a cleaner, brighter space today.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Car Cleaning</u></b></h2>
      <p>
          Drive in style with our meticulous car washing services. Whether it's a sedan, SUV, luxury vehicle, dirtbike, or side by side our team provides a thorough clean that rejuvenates your car's appearance. We use top-grade products to ensure a gentle yet effective cleanse, giving your vehicle the shine it deserves. Choose our car washing service for a spotless, gleaming ride.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Trash Bin Cleaning</u></b></h2>
      <p>
        Eliminate unpleasant odors and unsightly residues with our trash bin cleaning service. Bins can harbor harmful bacteria and fungi, posing health risks. Our specialized cleaning process disinfects and deodorizes your bins, promoting a healthier environment. Schedule a session today for cleaner, fresher-smelling bins.
      </p>
      <br/>
      </Container>

      <Container>
      <h2><b><u>Gutter Cleaning</u></b></h2>
      <p>Avoid costly repairs with our gutter cleaning services. We clear your gutters of leaves and debris to ensure proper water flow and prevent damage to your home.</p>
      <br/>
      </Container>
           
      
      {/* <Footer />             */}
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>

    </Container>
  );
}

export default Services;