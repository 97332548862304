import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';

import Fonted_Logo2 from '../Images/Fonted_Logo2.png';

import '../App.css';

function NavBar() {
  const location = useLocation();
  const showLogoAndHome = location.pathname !== "/";
  const [expanded, setExpanded] = useState(false);

  return (
     <Navbar collapseOnSelect expand="lg"  fixed="top" expanded={expanded} class="navbar">
      <Container>               
          <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
            <img src={Fonted_Logo2} style={{width: "115px", height: "115px"}} alt="logo" className='navbarLogo' />
          </Navbar.Brand>          
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {/* location.pathname !== "/" &&   */}
            {/* location.pathname !== "/about" &&  */}
            {/* location.pathname !== "/services" && */}
            {/* location.pathname !== "/contact" && */}
            {<Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}><Button variant="outline-dark" className="Nav-button">Home</Button></Nav.Link>}
            {<Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}><Button variant="outline-dark" className="Nav-button">About</Button></Nav.Link>}
            {<Nav.Link as={Link} to="/services" onClick={() => setExpanded(false)}><Button variant="outline-dark" className="Nav-button">Services</Button></Nav.Link>}
            {<Nav.Link as={Link} to="/contact" onClick={() => setExpanded(false)}><Button variant="outline-dark" className="Nav-button">Contact</Button></Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;