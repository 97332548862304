import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './Contact.css';
import Footer from './Footer';
import HeroImage from '../Images/SD_FreshlyCleaned_73_2.jpg';


function Contact() {
  return (
    <Container>
      <br/>

      <Container className="hero_image">
        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image src={HeroImage} fluid style={{ maxWidth: "100%", height: "25%" }} alt="Aqua Bright Solar Cleaning Website Hero Image" />
        </Row>
      </Container>   

      <Container className='Contact_Page_Header'>
      <h1><b><u>Contact Us</u></b></h1>
      <br/>
                      
      </Container>

      <Container className='contact-page'>      
      <p>We're always here to help and answer any questions you may have. Whether you're looking for more information about our services, or you're ready to schedule an appointment, feel free to get in touch.</p>
      < br/>
      </Container>

      {/* <Container className='BlueSeparator'>
        <Row>
          <p></p>
          <p></p>
        </Row>
      </Container> */}

      {/* <Footer />             */}
          
    
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
      

    </Container>


    
  );
}

export default Contact;