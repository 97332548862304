import React from 'react';
import { Container, Row, Col, Image, Button, Carousel, CardDeck, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeroImage from '../Images/SD_FreshlyCleaned_73_1.jpg';
import CImage2 from '../Images/Freshly Cleaned Array Long Shot.jpg';
import CImage3 from '../Images/Half Cleaned Array Edited.jpg';

import ServiceImage1 from '../Images/Freshly Cleaned Solar Array_2.jpg'; 
import ServiceImage2 from '../Images/Freshly Cleaned Windows Commercial_2.jpg'; 
import ServiceImage3 from '../Images/Freshly Pressure Washed Driveway.jpg';
import ServiceImage4 from '../Images/Freshly Cleaned Truck.jpg';

import ActionShot_1 from '../Images/Washing Away Grime Muted.mp4';
import ActionShot_2 from '../Images/Solar Cleaning Action Shot Cropped.mp4';

import ServiceArea from '../Images/Service Area.jpg'

import Footer from './Footer.js';

import '../App.css';

function Home() {
  return (
    <Container>

      <Container fluid className="hero_image">        
        <Carousel interval={3000} controls={true} style={{ width: "100%" }}>
        <Carousel.Item>
            <img
              className="d-block w-100"
              src={HeroImage}
              alt="First slide" />
            <Carousel.Caption>
              <h1><b>Aqua Bright Solar Cleaning</b></h1>
              <p>Efficient Solutions, Friendly Faces</p>
            </Carousel.Caption>
        </Carousel.Item>

        {/* Add more <Carousel.Item> components for additional images */}
        <Carousel.Item>
            <img
              className="d-block w-100"
              src={CImage2}
              alt="First slide" />
            <Carousel.Caption>
              <h1><b>Aqua Bright Solar Cleaning</b></h1>
              <p>Efficient Solutions, Friendly Faces</p>
            </Carousel.Caption>
        </Carousel.Item> 

        {/* Add more <Carousel.Item> components for additional images */}
        <Carousel.Item>
            <img
              className="d-block w-100"
              src={CImage3}
              alt="First slide" />
            <Carousel.Caption>
              <h1><b>Aqua Bright Solar Cleaning</b></h1>
              <p>Efficient Solutions, Friendly Faces</p>
            </Carousel.Caption>
        </Carousel.Item>  

        {/* Add more <Carousel.Item> components for additional images */}        

      </Carousel>
    </Container> 
    {/* End of Carousel */}
    

    {/* Services Section */}

    {/* Service Section Title */}
      <Container className='Services_Section'>
        <Row className="justify-content-center my-4">
          <Col xs={12} className="text-center">
              <Link to="/services">
                  <h1 className='Services_Title_Home'><b>Premium Services</b></h1>
              </Link>
          </Col>
        </Row>


        {/* Hero Paragraph */}
        <Row className="Home_Page_Body">
          <Col>
              <p>
                  At ABSC, we specialize in offering premier cleaning services that resonate with quality, efficiency, and reliability. Our services aren't just about cleaning; they're about ensuring optimal performance, efficiency, and longevity. 
              </p>
              <p>
                  With state-of-the-art equipment, and a team of dedicated professionals, we're committed to bringing out the best in your investments. From solar panels that shine brighter to windows that sparkle clearer — we promise unparalleled service.
              </p>
          </Col>
        </Row>
        < br/>
      </Container>

      {/* Service Cards */}
      <Container className='ServiceCards'>   
      <br/>   
      <Row>
      <Col md={6}>
        <div className="services-overview">                
            <Card className="mb-3">
              <Card.Img variant="top" src={ServiceImage1} />
              <Card.Body>
              <Card.Title>Solar Panel Cleaning</Card.Title>
              <Card.Text>
                  At ABSC, we are the best choice for solar panel cleaning service. We are committed to providing you with the best service possible. 
              </Card.Text>
              <Link to="/services">
              <Button variant="primary" >Learn more</Button>
              </Link>
              </Card.Body>
            </Card>
            </div>
        </Col>

        <Col md={6}>
        <Card className="mb-3">
          <Card.Img variant="top" src={ServiceImage2} />
          <Card.Body>
          <Card.Title>Window Washing</Card.Title>
          <Card.Text>
            ABSC is available 24/7 for Residential and Commercial Window Cleaning. We use professional tools, deionized water, and always leave your windows spotless. 
          </Card.Text>
          <Link to="/services">
          <Button variant="primary" >Learn More</Button>
          </Link>
          </Card.Body>
        </Card>
          </Col>          
      </Row>

      <Row>
      <Col md={6}>
        <div className="services-overview">                
            <Card className="mb-3">
              <Card.Img variant="top" src={ServiceImage3} />
              <Card.Body>
              <Card.Title>Pressure Washing</Card.Title>
              <Card.Text>
                  ABSC is happy to bring back your driveway, pool deck, back patio, and more to their former glory. With the best tools, we'll get rid of the toughest stains.
              </Card.Text>
              <Link to="/services">
              <Button variant="primary">Learn more</Button>
              </Link>
              </Card.Body>
            </Card>
            </div>
          </Col>
          <Col md={6}>
          <Card className="mb-3">
                <Card.Img variant="top" src={ServiceImage4} />
                <Card.Body>
                    <Card.Title>Car Cleaning</Card.Title>
                    <Card.Text>
                    For a car cleaning service that you can trust, we offer a variety of options to suit your needs.
                    </Card.Text>
                    <Link to="/services">
                    <Button variant="primary">Learn more</Button>
                    </Link>
                </Card.Body>
            </Card>
          </Col>          
      </Row>
      <br/>
      </Container>
    {/* End of Service Cards */}

    {/* Why Clean Panels */}
    <Container>
      <Row>
        <Col xs={12}>
          <Link to="/about">
              <br/>
              <h2 className='Why_Clean_Title'><b>The Importance of Solar Panel Cleaning</b></h2>
              <br/>
          </Link>
          <p className='Why_Clean'>Solar panels are marvels of modern engineering, harnessing the sun's energy to provide us with a power source. However, like any technological asset, they require proper maintenance to function at their peak. One essential aspect of this maintenance is regular cleaning.</p> 
        </Col>          
      </Row>
    </Container>

    {/* Video Section */}
    <Container className='Video_Showcase'>
    <Row>
        <Col className="video-container">
            <video controls autoplay muted loop>
                <source src={ActionShot_2} type="video/mp4" /> {/* Assuming ActionShot_2 is your second video */}
                Your browser does not support the video tag.
            </video>
        </Col>    
    </Row>
    <br/>
    </Container>

    <Container className='Why_Clean'>         
        <p>
          <b>Maximizing Efficiency:</b> Over time, solar panels can accumulate dust, pollen, bird droppings, and other debris. This layer of grime can significantly obstruct the panels' ability to absorb sunlight. In fact, studies have shown that dirty solar panels can lose between 15% to 25% of their efficiency compared to clean ones. This means less power generation and a higher electricity bill. Cleaning your solar panels ensures they operate at maximum efficiency, translating into optimal power output and monetary savings.
          </p>
        <p>
          <b>Warranty and Lease Agreements:</b> Keeping your solar panels clean isn't just about efficiency—it may also be a contractual requirement. Many warranties and lease agreements stipulate that panels need to be maintained and cleaned regularly. Failure to comply might not only void your warranty or lease but could also lead to unforeseen repair or replacement costs in the future.
        </p>
        <p>
        <b>The Myth of Rain:</b> A common misconception is that rain will naturally clean solar panels. While rain can wash away some loose dirt, it doesn't effectively remove all accumulated debris. Moreover, rain can leave behind mineral deposits, leading to a film on the panels that can further reduce their efficiency. Relying solely on rain to clean your solar panels is like expecting a drizzle to give your car a thorough wash—it just won't do the job adequately.
        </p>
        <p>
        <b>In conclusion:</b> Regular solar panel cleaning is not only essential for maximizing efficiency and power output but is also crucial in upholding warranty or lease agreements. Don't let myths deter you from proper maintenance; investing in regular cleaning can save you money in the long run and ensure your panels function at their best for years to come.
        </p>    
    </Container>


    {/* Call Us Button */}
    <Container>
        <br/>      
      <Row>
        <Button size="lg" href="tel:602-576-7729" className='HomePageButton'>
        Call Us Today!
        </Button>
      </Row>
    </Container>
    < br/>


    {/* Why Choose Us */}
    <Container>
      <Row>
        <Col xs={12}>
          <Link to="/about">
              <br/> 
              <h2 className='Why_Choose_Us_Title'><b>Why Choose ABSC</b></h2>
              <br/>  
          </Link>
        </Col>          
      </Row>
    </Container>

    <Container className='Why_Choose_Us'>
    <Row>      
        <p>At Aqua Bright Solar Cleaning, we offer top-notch services and we take pride in our work.</p> 
        <p>We're on your side and we want to ensure that you are able to depend on us when it comes to protecting and cleaning your investments.</p>
        <p>With our experience and know-how, we guarantee a thorough cleaning every time.</p>
        <br/>
    </Row>
    </Container>


    {/* Service Area */}
    <Container className='ServiceArea_Home'>    
        <Container>
          <Row>
            <Col xs={12}>
              <Link to="/services">
                  <br/> 
                  <h2 className='ServicesArea_Title_Home'><b>Service Area</b></h2>
                  <br/>  
              </Link>
            </Col>          
          </Row>
        </Container>

        <Container className='ServiceArea_Body_Home'>
        <Row>
        ABSC proudly offers services to the majority of Maricopa County, including:
        < br/>
        < br/>
          <Col md={6}> 
              <ul>
                <li>Gilbert</li>
                <li>Scottsdale</li>
                <li>Chandler</li>
                <li>Queen Creek</li>
              </ul> 
            </Col>
            <Col md={6}>
            <ul>
                <li>Tempe</li>
                <li>Phoenix</li>
                <li>Mesa</li>
                <li>Apache Junction</li>
              </ul>         
            </Col>
            <br/>
        </Row>
        <Row>
        <img
              className="d-block w-100"
              src={ServiceArea}
              alt="ABSC Service Area" />
        </Row>
        </Container>
        < br/>
        < br/>
        < br/>
        
    </Container>


      {/* <Container>
      <p>Aqua Bright Solar Cleaning offers residential and commerical solar panel cleaning services for Gilbert, Scottsdale, Chandler, Tempe, Mesa, and Apache Junction</p>
      <p>Aqua Bright Solar Cleaning offers other services as well, including: </p>
      <p>Schedule an appointment today to enjoy more efficient solar energy without any hassles!</p>                
      <br/>       
    </Container>      */}
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    {/* <Footer />             */}
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    < br/>
    </Container>
  );
}

export default Home;