import React from 'react';
import './StickyFooter.css';
import {Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const StickyFooter = () => {
    return (
        <div className="sticky-footer">
            <Button href="tel:602-576-7729" className="stickyFooterNumber">
            602-576-7729
            </Button>          
            
            {/* //Add Social Media links
            <p>Social Media links</p> */}

            <div className="social-media-links">
                <a href="https://twitter.com/ABSC_AZ" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a href="https://www.facebook.com/people/Aqua-Bright-Solar-Cleaning/61554597585450" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="https://www.instagram.com/absc_az" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
            </div>

            <p>© 2023 Aqua Bright Solar Cleaning. All rights reserved.</p>
           
        </div>
    );
}

export default StickyFooter;